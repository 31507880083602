<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="noticeManageDetail">
		<div style="margin: 20px 0;" class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">标题</span>
				<el-input class="common-row-ipt" placeholder="请输入" v-model="noticeForm.title" />
			</div>
		</div>
		<quillEditor @editorChange="editorChange" :upLoadImage="noticeType == '工时证明说明'?true:false"
			:intEditor.sync="noticeForm.content" />
		<div class="noticeManageDetail-btn">
			<el-button @click="submit" type="primary">确定修改</el-button>
		</div>
	</div>
</template>

<script>
	import quillEditor from "../../../components/quillEditor.vue";
	import {
		detailNotice,
		editNotice,
	} from "@/api/system";
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				noticeId: "",
				noticeType: "",
				noticeForm: {},
			};
		},
		created() {
			this.noticeId = this.$route.query.noticeId
			this.noticeType = this.$route.query.noticeType
			this.getNoticeDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			editorChange(props) {
				console.log("editorChange", props)
				this.noticeForm.content = props
			},
			getNoticeDetail() {
				detailNotice(this.noticeId, {
					id: this.noticeId
				}).then((res) => {
					if (res.code == 0) {
						this.noticeForm = res.data
					}
				})
			},
			submit() {
				let params = JSON.parse(JSON.stringify(this.noticeForm))
				if (!params.content) {
					this.$message.error("请输入公告内容~")
					return
				}
				editNotice(params.id, params).then((res) => {
					if (res.code == 0) {
						this.$message.success("公告编辑成功~")
						this.$router.go(-1)
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #dcdfe6;
	}

	.noticeManageDetail {
		.noticeManageDetail-btn {
			margin-top: 80px;
		}
	}
</style>
